import { navigate } from 'gatsby';
import * as React from 'react';

function NotFoundPage() {
  React.useEffect(() => {
    navigate('/');
  }, []);

  return null;
}

export default NotFoundPage;
